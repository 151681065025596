import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/modules/app-core/breadcrumbs/breadcrumb.service';
import { IBandMember } from 'src/app/modules/app-core/models/IBandMember';
import { UIService } from 'src/app/modules/shared/ui-service/ui.service';

@Component({
  selector: 'app-the-band-page',
  templateUrl: './the-band-page.component.html',
  styleUrls: ['./the-band-page.component.scss']
})
export class TheBandPageComponent implements OnInit {
  public members: IBandMember[] = [
    {
      firstName: 'James',
      lastName: 'Lewis',
      instrument: 'drums'
    },
    {
      firstName: 'Mel',
      lastName: 'Skiggs',
      instrument: 'tenor saxophone'
    },
    {
      firstName: 'Jim',
      lastName: 'Stidham',
      instrument: 'bass'
    },
    {
      firstName: 'Richard',
      lastName: 'Martin',
      instrument: 'piano and keyboard'
    },
    {
      firstName: 'Andreas',
      lastName: 'van Greunen',
      instrument: 'guitar'
    },
  ];

  public pastMembers: IBandMember[] = [
    {
      firstName: 'Keith',
      lastName: 'Simpson',
      instrument: 'tenor saxophone'
    },
    {
      firstName: 'David',
      lastName: 'Bishop',
      instrument: 'trumpet'
    }
  ];

  constructor(private uiService: UIService, private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.uiService.setMetaData({
      title: 'The Band',
      description: 'Present and past members of the Area 251 band',
      image: ''
    });

    this.breadcrumbService.breadcrumbs = [this.breadcrumbService.buildBandPageBreadcrumb()];
  }

}
