
<div class="video-container">
  <div id="mask"></div>
  <div id="video" *ngIf="sanitisedUrl">
    <iframe [src]="sanitisedUrl"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
  </iframe>

  </div>
</div>

