import { Injectable } from '@angular/core';
import { IBreadcrumbItem } from './IBreadcrumbItem';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  constructor() {}

  private breadcrumbChangeSource: Subject<IBreadcrumbItem[]> = new Subject<IBreadcrumbItem[]>();
  public breadcrumbChanges$ = this.breadcrumbChangeSource.asObservable();

  private _breadcrumbs: IBreadcrumbItem[] = [];

  public set breadcrumbs(value: IBreadcrumbItem[]) {
    this._breadcrumbs = value;
    setTimeout(() => this.breadcrumbChangeSource.next(this._breadcrumbs));
  }

  public reset(): void {
    this.breadcrumbs = [];
  }

  public buildGigsPageBreadcrumb(): IBreadcrumbItem {
    return {
      label: 'Gigs',
      url: '/gigs'
    };
  }

  public buildVideosPageBreadcrumb(): IBreadcrumbItem {
    return {
      label: 'Videos',
      url: '/videos'
    };
  }

  public buildBandPageBreadcrumb(): IBreadcrumbItem {
    return {
      label: 'The Band',
      url: '/the-band'
    };
  }

}
