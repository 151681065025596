<main>
  <h1>Videos</h1>

  <ng-container *ngIf="videos$ | async as videos" >

    <div *ngFor="let video of videos" class="video-block">
      <!-- <iframe [src]="video?.url"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe> -->
      <app-masked-you-tube-video [videoUrl]="video?.url"></app-masked-you-tube-video>

      <caption class="subdued">{{ video?.description }}</caption>
    </div>

  </ng-container>

</main>
