import { Component } from '@angular/core';

@Component({
  selector: 'app-main-images-block',
  templateUrl: './main-images-block.component.html',
  styleUrls: ['./main-images-block.component.scss']
})
export class MainImagesBlockComponent {

  constructor() { }

}


