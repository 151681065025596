import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ISiteMetadata } from './ISiteMetadata';

@Injectable({
  providedIn: 'root'
})
export class UIService {

  private appColor = '#151515';
  private appImage = '/assets/images/area251-band-meta.png';
  private appTitle = 'Area 251 - London jazz, blues and funk';
  private appDescription = `Area 251 is an instrumental jazz, blues and funk band based in London.
  The band features James Lewis (drums), Jim Stidham (bass), Richard Martin (piano and keyboards),
  Andreas van Greunen (guitar) and Mel Skiggs (tenor saxophone).`;

  constructor(private meta: Meta, private title: Title) { }

  public setMetaData(config: ISiteMetadata): void {
    const image = `${environment.url}${config.image || this.appImage}`;
    if (config.description) {
      config.description = config.description.endsWith('.') ? config.description : `${config.description}.`;
    }

    const description = config.description ?  `${config.description} ${this.appDescription}` : this.appDescription;
    const title = config.title ? `${this.appTitle} - ${config.title}` : this.appTitle;

    // set the application title:
    this.title.setTitle(title);

    const tags = [
      { name: 'description', content: description },
      { name: 'theme-color', content: this.appColor },
      { name: 'twitter:card', content: 'summary'},
      { name: 'twitter:image', content: image },
      { name: 'twitter:title', content: title},
      { name: 'twitter:description', content: description},

      { name: 'apple-mobile-web-app-capable', content: 'yes'},
      { name: 'apple-mobile-web-app-status-bar-style', content: 'translucent'},
      { name: 'apple-mobile-web-app-title', content: title},

      { name: 'og:title', content: title},
      { name: 'og:description', content: description},
      { name: 'og:image', content: image},
    ];

    tags.forEach(tag => this.meta.updateTag(tag));
  }

}
