
<div id="main-toolbar">
  <div id="logo">
    <a [routerLink]="['']">
      <div class="logo-main">Area 251</div>
      <div class="logo-sub subdued1">London Jazz, Blues & Funk</div>
    </a>
  </div>

  <app-menu [menuItems]="menuItems"></app-menu>


</div>

<app-breadcrumbs *ngIf="!userIsOnHomePage"></app-breadcrumbs>

<div id="router-outlet">
  <router-outlet></router-outlet>
</div>
