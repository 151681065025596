import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/modules/app-core/breadcrumbs/breadcrumb.service';
import { UIService } from 'src/app/modules/shared/ui-service/ui.service';

@Component({
  selector: 'app-gigs-page',
  templateUrl: './gigs-page.component.html',
  styleUrls: ['./gigs-page.component.scss']
})
export class GigsPageComponent implements OnInit {

  constructor(private uiService: UIService, private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.uiService.setMetaData({
      title: 'Gigs',
      description: 'Gigs featuring the Area 251 band',
      image: ''
    });

    this.breadcrumbService.breadcrumbs = [this.breadcrumbService.buildGigsPageBreadcrumb()];
  }

}
