<main>
  <h1>The Band</h1>

  <mat-card class="band-members-card">
    <mat-card-content>
      <ul>
        <li *ngFor="let member of members">
          <span>{{member.firstName}} {{member.lastName}}</span>
          <span class="subdued instrument">({{member.instrument}})</span>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card class="band-members-card">
    <mat-card-header>
      <mat-card-title><h2>Past Members:</h2></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ul>
        <li *ngFor="let member of pastMembers">
          <span>{{member.firstName}} {{member.lastName}}</span>
          <span class="subdued instrument">({{member.instrument}})</span>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
</main>

