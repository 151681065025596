import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { IMenuItem } from './modules/shared/menu/IMenuItem';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public menuItems: IMenuItem[] = [
    {
      text: 'Home',
      route: ''
    },
    {
      text: 'Gigs',
      route: '/gigs'
    },
    {
      text: 'The Band',
      route: '/the-band'
    },
    {
      text: 'Videos',
      route: '/videos'
    },
  ];

  constructor(private location: Location) {}

  public get userIsOnHomePage(): boolean {
    return (this.location.path() === '');
  }

}
