import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IWebResource } from '../models/IWebResource';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public videos$: Observable<IWebResource[]> = this.httpClient.get<IWebResource[]>(`${environment.url}/assets/data/videos.json`)
                                            .pipe(shareReplay(1));

  public mainImages$: Observable<IWebResource[]> = this.httpClient.get<IWebResource[]>(`${environment.url}/assets/data/images.json`)
                                            .pipe(shareReplay(1));

  constructor(private httpClient: HttpClient) { }

}
