import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from './breadcrumb.service';
import { IBreadcrumbItem } from './IBreadcrumbItem';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  public breadcrumbs$: Observable<IBreadcrumbItem[]> = this.breadcrumbService.breadcrumbChanges$;

  constructor(private breadcrumbService: BreadcrumbService, private router: Router) {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbChanges$
  }

  public navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

}
