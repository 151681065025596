import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-masked-you-tube-video',
  templateUrl: './masked-you-tube-video.component.html',
  styleUrls: ['./masked-you-tube-video.component.scss']
})
export class MaskedYouTubeVideoComponent implements OnInit, OnChanges {
  @Input() videoUrl: string | undefined = undefined;

  public sanitisedUrl: SafeResourceUrl = '';

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.videoUrl) {
      this.sanitisedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.appendVideoSettings(this.videoUrl));
    }
  }

  private appendVideoSettings(url: string): string {
    return `${url}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0`;
  }

}
