<div id="breadcrumbs">
  <ul>
    <li>
      <button mat-icon-button [routerLink]="['/']" class="breadcrumb-item">
        <mat-icon>home</mat-icon>
      </button>
    </li>
    <ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
      <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
          <span>></span>
          <span><a (click)="navigateTo(crumb.url)">{{ crumb.label }}</a></span>
      </li>
    </ng-container>
  </ul>
</div>

