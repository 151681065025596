<main>

  <div id="home-page-images">
    <app-main-images-block></app-main-images-block>
  </div>


  <!-- <div id="video">
    <iframe src="https://www.youtube.com/embed/EDFS2092GB4?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
    </iframe>
  </div> -->

  <div id="home-page-video">
    <app-masked-you-tube-video videoUrl="https://www.youtube.com/embed/EDFS2092GB4"></app-masked-you-tube-video>
  </div>


</main>

