import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/app-core/api-service/api.service';
import { BreadcrumbService } from 'src/app/modules/app-core/breadcrumbs/breadcrumb.service';
import { IWebResource } from 'src/app/modules/app-core/models/IWebResource';
import { UIService } from 'src/app/modules/shared/ui-service/ui.service';

@Component({
  selector: 'app-videos-page',
  templateUrl: './videos-page.component.html',
  styleUrls: ['./videos-page.component.scss']
})
export class VideosPageComponent implements OnInit {

  public videos$: Observable<IWebResource[]> = of([]);

  constructor(private uiService: UIService,
              private apiService: ApiService,
              private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.uiService.setMetaData({
      title: 'Videos',
      description: 'Performance videos featuring the Area 251 band',
      image: ''
    });

    this.videos$ = this.apiService.videos$;

    this.breadcrumbService.breadcrumbs = [this.breadcrumbService.buildVideosPageBreadcrumb()];
  }

}
