import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageGridComponent } from './image-grid/image-grid.component';
import { MainImagesBlockComponent } from './main-images-block/main-images-block.component';



@NgModule({
  declarations: [ImageGridComponent, MainImagesBlockComponent],
  imports: [
    CommonModule
  ],
  exports: [ ImageGridComponent, MainImagesBlockComponent ]
})
export class AppCoreModule { }
