import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GigsPageComponent } from './pages/gigs-page/gigs-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TheBandPageComponent } from './pages/the-band-page/the-band-page.component';
import { VideosPageComponent } from './pages/videos-page/videos-page.component';

const routes: Routes = [
  { path: 'the-band', component: TheBandPageComponent },
  { path: 'videos', component: VideosPageComponent },
  { path: 'gigs', component: GigsPageComponent },
  { path: '', component: HomePageComponent }
  // { path: '', pathMatch: 'full', redirectTo: 'gigs' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
