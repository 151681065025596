<!-- <ng-container *ngIf="images$ | async as images">
  <app-image-grid [images]="images"></app-image-grid>
</ng-container> -->


<div id="mobile">
  <ul class="mobile-image-grid">
    <li class="mobile-image-grid-item">
      <img src="assets/images/andreas.png" class="img-regular" loading="lazy">
    </li>
    <li class="mobile-image-grid-item">
      <img src="assets/images/mel.png" class="img-regular" loading="lazy">
    </li>
    <li class="mobile-image-grid-item">
      <img src="assets/images/james.png" class="img-regular" loading="lazy">
    </li>

  </ul>

  <ul class="mobile-image-grid">
    <li class="mobile-image-grid-item">
      <img src="assets/images/richard-square.png" class="img-large" loading="lazy">
    </li>
    <li class="mobile-image-grid-item">
      <img src="assets/images/jim-square.png"  class="img-large" loading="lazy">
    </li>
  </ul>

</div>

<div id="non-mobile">
  <ul class="blocks-gallery-grid">
    <li class="blocks-gallery-item">
      <img src="assets/images/andreas.png" class="img-regular" loading="lazy">
    </li>
    <li class="blocks-gallery-item">
      <img src="assets/images/richard.png" class="img-regular" loading="lazy">
    </li>
    <li class="blocks-gallery-item">
      <img src="assets/images/mel.png" class="img-regular" loading="lazy">
    </li>
    <li class="blocks-gallery-item">
      <img src="assets/images/jim.png" class="img-regular" loading="lazy">
    </li>
    <li class="blocks-gallery-item">
      <img src="assets/images/james.png" class="img-regular" loading="lazy">
    </li>
  </ul>

</div>
