<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Main menu" class="btn-mobile">
  <mat-icon>more_vert</mat-icon>
</button>

<button mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Main menu" class="btn-tablet">
  <mat-icon>more_vert</mat-icon>Menu
</button>

<mat-menu #menu="matMenu">

  <ng-container *ngFor="let item of menuItems">
    <button mat-menu-item [routerLink]="[item.route]" >
      <span>{{ item.text }}</span>
    </button>
  </ng-container>

</mat-menu>

<ul id="large-menu">
  <li *ngFor="let item of menuItems">
    <a [routerLink]="[item.route]"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true}"
    >{{ item.text }}</a>
  </li>
</ul>
